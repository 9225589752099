import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { redirectToService } from '../../functions/helperFunctions';

const Takuutarkastus = () => {
    // Extract Redux state outside useEffect
    const queryParamsFromState = useSelector((state) => state.queryParameter.queryParameter);
    const languageInfo = useSelector((state) => state.language);

    useEffect(() => {
        // Browser culture information
        const browserCulture = document.documentElement.dataset.culture || 'fi-FI';
        const [browserLang, browserCountry] = browserCulture.split('-');

        // Determine selected language
        const selectedLang = languageInfo.locale ? languageInfo.locale.split('-')[0] : browserLang;

        // Build query parameters
        const queryParams = new URLSearchParams(window.location.search);
        const queryParamsWithQuestionMark = queryParamsFromState
            ? queryParamsFromState
            : queryParams.size > 0 && queryParams.has('countryCode')
                ? `?${queryParams}&lang=${selectedLang}`
                : queryParams.size > 0 && !queryParams.has('countryCode')
                    ? `?${queryParams}&countryCode=${browserCountry}&lang=${selectedLang}`
                    : `?countryCode=${browserCountry}&lang=${selectedLang}`;

        // Redirect to the service
        redirectToService('/takuutarkastus' + queryParamsWithQuestionMark);
    }, [queryParamsFromState, languageInfo]); // Dependency array includes Redux states

    return (
        <div>
            <h1>Ohjataan, odota hetki...</h1>
        </div>
    );
};

export default Takuutarkastus;
