import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import TooltipButton from '../TooltipButton/TooltipButton';

const CustomerInfo = () => {

    const dispatch = useDispatch();
    const intl = useIntl();
    const customer = useSelector(state => state.customer);
    const locale = useSelector(state => state.language.locale);

    const culture = document.documentElement.dataset.culture;
    const cultureCountry = culture.split('-')[1];

    const language = locale ? locale.split('-')[0] : 'fi';

    const onlineAccountUrl = `https://customer.bmwgroup.com/oneid/#/login?client=bmwwebcom&country=${cultureCountry}&language=${language}&brand=bmw`;

    const updateCustomerInfo = (event) => {

        let fieldValue = event.target.value;

        if (event.target.type === 'checkbox') {
            fieldValue = event.target.checked;
        }
        const type = event.target.name + 'Updated';

        dispatch({
            type: type, payload: {
                value: fieldValue,
                name: event.target.name
            }
        });
    };

    const toggleTooltipTextVisibility = (field) => {
        let currentTooltipTextVisibility = null;

        switch (field) {
            case 'MarketingAllowed':
                currentTooltipTextVisibility = customer.MarketingAllowedTooltipTextVisibility;
                break;

            case 'AnalyticsAllowed':
                currentTooltipTextVisibility = customer.AnalyticsAllowedTooltipTextVisibility;
                break;

            case 'AllowanceCancel':
                currentTooltipTextVisibility = customer.AllowanceCancelTooltipTextVisibility;
                break;

            case 'RemoteKeyRead':
                currentTooltipTextVisibility = customer.RemoteKeyReadTooltipTextVisibility;
                break;

            default:
                break;
        }

        const tooltipTextVisibility = currentTooltipTextVisibility === "hide" ? "show" : "hide";

        dispatch({
            type: field + 'TooltipTextVisibilityChanged', payload: tooltipTextVisibility
        });
    };


    return (
        <div>
            <section className="margin-top-m">
                <div className="container">
                    <div className="row d-flex">
                        <div className="col-12 col-lg-10 offset-lg-1 bg-w padding-top-m-scale padding-hor-l-scale">
                            <h2 className="visual-h3"><FormattedMessage id="customerInfo.header" /></h2>
                            <div className="row d-flex">
                                <div className="col-12 col-md-6">
                                    <div className="bmw-text-input">
                                        <label htmlFor="first-name"><FormattedMessage id="customerInfo.firstName" /></label>
                                        <span className="wrapper">
                                            <input type="text" id="first-name" value={customer.FirstName.value} name="FirstName" placeholder="" onChange={(event) => updateCustomerInfo(event)} />
                                        </span>
                                        <small className="text-error"><FormattedMessage id={customer.FirstName.error} /></small>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="bmw-text-input">
                                        <label htmlFor="last-name"><FormattedMessage id="customerInfo.lastName" /></label>
                                        <span className="wrapper">
                                            <input type="text" id="last-name" value={customer.LastName.value} name="LastName" placeholder="" onChange={(event) => updateCustomerInfo(event)} />
                                        </span>
                                        <small className="text-error"><FormattedMessage id={customer.LastName.error} /></small>
                                    </div>
                                </div>
                            </div>
                            <div className="row d-flex">
                                <div className="col-12 col-md-6">
                                    <div className="bmw-text-input">
                                        <label htmlFor="email"><FormattedMessage id="customerInfo.email" /></label>
                                        <span className="wrapper">
                                            <input type="email" id="email" value={customer.Email.value} name="Email" placeholder="" onChange={(event) => updateCustomerInfo(event)} />
                                        </span>
                                        <small className="text-error"><FormattedMessage id={customer.Email.error} /></small>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="bmw-text-input">
                                        <label htmlFor="tel"><FormattedMessage id="customerInfo.mobilephone" /></label>
                                        <span className="wrapper">
                                            <input type="tel" id="tel" value={customer.PhoneNumber.value} name="PhoneNumber" placeholder="" onChange={(event) => updateCustomerInfo(event)} />
                                        </span>
                                        <small className="text-error"><FormattedMessage id={customer.PhoneNumber.error} /></small>
                                    </div>
                                </div>
                            </div>
                            <div className="row d-flex">
                                <div className="col-12">
                                    <div className="bmw-textarea">
                                        <label htmlFor="more-info"><FormattedMessage id="customerInfo.additionalDetails" /><span className="muted">(<FormattedMessage id="customerInfo.voluntary" />)</span></label>
                                        <span className="wrapper">
                                            <textarea id="more-info" name="Info" value={customer.Info.value} placeholder="" onChange={(event) => updateCustomerInfo(event)} ></textarea>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="row d-flex">
                                <div className="col-auto pe-0">
                                    <div className="bmw-checkbox">
                                        <div className="bmw-form-item-inline">
                                            <input type="checkbox" id="keyReadConsent" checked={customer.RemoteKeyRead.value} 
                                                name="RemoteKeyRead" onChange={(event) => updateCustomerInfo(event)} />
                                            <label htmlFor="keyReadConsent">
                                                <FormattedMessage id="customerInfo.remoteKeyRead" /> 
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-auto ps-0">
                                    <TooltipButton tooltipFunction={toggleTooltipTextVisibility} tooltipParameter={"RemoteKeyRead"} />
                                </div>
                                <div style={{ position: "relative" }}>
                                    <div className={customer.RemoteKeyReadTooltipTextVisibility + " tooltipWrapper"}>
                                        <div className="modal-close_terms_wrapper" >
                                            <span className="modal-close_terms" onKeyPress={() => toggleTooltipTextVisibility("RemoteKeyRead")}
                                                onClick={() => toggleTooltipTextVisibility("RemoteKeyRead")} tabIndex="0">X
                                            </span>
                                        </div>
                                        <FormattedMessage id="customerInfo.remoteKeyRead.moreinfo" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="disclaimer" className="margin-top-m">
                <div className="container">
                    <div className="row d-flex">
                        <div className="col-12 col-lg-10 offset-lg-1 bg-w padding-hor-l-scale">
                            <p className="muted">
                                <FormattedMessage id="customerInfo.personInfoHandling" />
                                <a
                                    href={intl.formatMessage({ id: "footer.privacyPolicy.url" })}
                                    target="_blank"
                                    aria-label={intl.formatMessage({ id: "customerInfo.dataSafetyInfo.read.dataSafetyInfo" })}
                                >
                                    <FormattedMessage id="footer.privacyPolicy.url" />
                                </a>
                            </p>
                            <ul>
                                <li>
                                    <div className="bmw-checkbox">
                                        <div className="bmw-form-item-inline">
                                            <input type="checkbox" id="consent-1" checked={customer.DataSafetyInfo.value} name="DataSafetyInfo" onChange={(event) => updateCustomerInfo(event)} />
                                            <label htmlFor="consent-1">
                                                <FormattedMessage id="customerInfo.privacyPolicyRead" />
                                            </label>
                                            <div className="read-cookie-policy">
                                                <small className="text-error"><FormattedMessage id={customer.DataSafetyInfo.error} /></small>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="bmw-checkbox">
                                        <div className="bmw-form-item-inline">
                                            <input type="checkbox" id="consent-2" checked={customer.MarketingAllowed.value} name="MarketingAllowed" onChange={(event) => updateCustomerInfo(event)} />
                                            <label htmlFor="consent-2">
                                                <FormattedMessage id="customerInfo.marketingInfo" />
                                                <br />
                                                <br />
                                                <FormattedMessage id="customerInfo.marketingInfo2" />
                                                <br />
                                                <br />
                                                <FormattedMessage id="customerInfo.marketingInfo3" />
                                                <br />
                                                <br />
                                                <FormattedMessage id="customerInfo.marketingInfo4" />
                                            </label>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div style={{ position: "relative" }}>
                                        <FormattedMessage id="customerInfo.allowanceCancel" />
                                        <TooltipButton tooltipFunction={toggleTooltipTextVisibility} tooltipParameter={"AllowanceCancel"} />

                                        <div className={customer.AllowanceCancelTooltipTextVisibility + " tooltipWrapper"}>
                                            <div className="modal-close_terms_wrapper" >
                                                <span className="modal-close_terms" onKeyPress={() => toggleTooltipTextVisibility("AllowanceCancel")}
                                                    onClick={() => toggleTooltipTextVisibility("AllowanceCancel")} tabIndex="0">X
                                                </span>
                                            </div>
                                            <h4><FormattedMessage id="customerInfo.allowanceCancel.moreInfoHeading" /></h4>
                                            <br />
                                            <FormattedMessage id="customerInfo.allowanceCancel.moreinfo" />
                                            <a href={onlineAccountUrl} target="_blank" aria-label={intl.formatMessage({ id: "customerInfo.allowanceCancel.moreinfo.linkText" })}><FormattedMessage id="customerInfo.allowanceCancel.moreinfo.linkText" /></a>
                                            <FormattedMessage id="customerInfo.allowanceCancel.moreinfo2" />
                                            <a href="tel:+358 (0)20 734 5920" target="_blank">+358 (0)20 734 5920 </a>
                                            <FormattedMessage id="customerInfo.allowanceCancel.moreinfo3" />
                                            <a href="mailto:nordic.dataprivacy@bmw.fi" target="_blank">nordic.dataprivacy@bmw.fi</a>
                                            <FormattedMessage id="customerInfo.allowanceCancel.moreinfo4" />
                                            <br />
                                            <br />
                                            <FormattedMessage id="customerInfo.allowanceCancel.moreinfo5" />
                                            <br />
                                            <br />
                                            <FormattedMessage id="customerInfo.allowanceCancel.moreinfo6" />
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default CustomerInfo;

