import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

const DealerDetails = () => {
    const currentDealer = useSelector(state => state.dealer.currentDealer);

    return (
        <div>
            <div className="visual-h4 bold"><FormattedMessage id="summary.dealerInfo.header" /></div>
            {
                currentDealer &&
                <ul className="service-point-details">
                    <li>{currentDealer.OfficeName}</li>
                    <li>{currentDealer.StreetAddress}</li>
                    <li>{currentDealer.ZipCode} {currentDealer.City}</li>
                    {
                        currentDealer.PhoneNo &&
                        <li><FormattedMessage id="summary.dealerInfo.telephone" /> {currentDealer.PhoneNo}</li>
                    }
                    <li><a href={"mailto:" + currentDealer.ReservationHandlers}>{currentDealer.ReservationHandlers}</a></li>
                </ul>
            }
        </div>
    );
};

export default DealerDetails;